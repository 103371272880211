.lyrics {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  max-width: 100%;
  padding: 10px;
  box-sizing: border-box;
  text-align: center;
  overflow: hidden; /* Ocultar la barra de desplazamiento */
}

.back-to-home-link img {
  height: 40px;
  width: 40px;
}

.back-to-home-link:hover img {
  opacity: 0.5;
}

.image-container {
  position: relative;
  max-width: 100%; /* Asegura que la imagen no exceda el ancho del contenedor */
}

.back-to-home-link {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  padding: 10px;
}

/* Media query para dispositivos con un ancho máximo de 375px (por ejemplo, iPhone SE) */
@media (max-width: 375px) {
  .lyrics {
    padding: 5px; /* Ajusta el espaciado para dispositivos más pequeños */
  }
}

/* Media query para dispositivos con un ancho máximo de 540px y alto máximo de 720px */
@media (max-width: 540px) and (max-height: 720px) {
  .lyrics {
    padding: 5px; /* Ajusta el espaciado para dispositivos más pequeños */
  }

  .image-container {
    max-width: 100vw; /* Utilizo el ancho completo del viewport */
    max-height: 70vh; /* Establece una altura máxima */
  }
}
