.container {
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
}

@font-face {
  font-family: "Nubifont";
  src: local("Nubifont-Regular"),
    url("./assets/fonts/Nubifont-Regular.ttf") format("truetype");
}

.page-enter {
  opacity: 0;
}

.page-enter-active {
  opacity: 1;
  transition: opacity 0.3s ease-in-out;
}

.page-exit {
  opacity: 1;
}

.page-exit-active {
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* O ajusta a la altura deseada */
}
