/* NotFound.css */

.not-found-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(0, 0, 0);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  z-index: 9999;
}

.not-found-heading {
  font-size: 3rem;
  color: #f8f8f8;
  margin-bottom: 20px;
}

.not-found-message {
  font-size: 1.5rem;
  color: #f8f8f8;
  margin-bottom: 20px;
}

.back-to-home-button {
  background-color: transparent; /* Cambia el color del botón según tu preferencia */
  color: #333; /* Cambia el color del texto del botón según tu preferencia */
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  text-decoration: none;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s;
}

.back-to-home-button:hover {
  background-color: #f8f8f8; /* Cambia el color al pasar el cursor por encima del botón */
}

.volver-button {
  font-family: "Nubifont";
  font-weight: bold;
}

.volver-button:hover {
  opacity: 0.5;
}