.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.header {
    font-family: "Nubifont";
    font-size: 3rem;
    margin-top: 0.5rem;
    text-align: center;
  }

.info {
    text-align: left;
    font-size: 18px;
    color: #555;
    line-height: 1.5;
    padding: 20px;
  }


/* Ajustes para dispositivos móviles */
@media only screen and (max-width: 768px) {
  .info {
    font-size: 16px;
    padding: 15px;
  }
}

@media only screen and (max-width: 375px) {
  .info {
    font-size: 14px;
    padding: 10px;
  }
}

/* Ajustes específicos para pantallas pequeñas como 280 x 653 */
@media only screen and (max-width: 280px) {
  .info {
    font-size: 12px;
    padding: 8px;
  }
}

/* Ajustes específicos para pantallas como 1024 x 600 */
@media only screen and (min-width: 601px) and (max-width: 1024px) {
  .info {
    font-size: 20px;
    padding: 20px;
  }
}

/* Estilos específicos para resoluciones de 280 x 653 */
@media (width: 280px) and (height: 653px) {
  .header {
    font-size: 2.5rem; /* Ajusta el tamaño de la fuente para la pantalla específica */
  }
}
