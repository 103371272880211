.social-container {
  display: flex;
  justify-content: flex-end; /* Alinea el contenido al extremo derecho */
  align-items: flex-start; /* Alinea el contenido en la parte superior */
  position: absolute;
  z-index: 9999;
  top: 16px;
  right: 10px;
  transition: transform 0.2s ease;
}

.social-link img {
  width: 40px;
  height: 40px;
}