.landing-page {
  position: relative;
  overflow: hidden;
}

.background-gradient {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* Media query para dispositivos con un ancho máximo de 375px (por ejemplo, iPhone SE) */
@media (max-width: 375px) {
  .landing-page {
    font-size: 14px; /* Ajusta el tamaño de fuente para dispositivos más pequeños */
  }

  .background-gradient {
    background-size: cover; /* Asegura que el fondo cubra completamente el área */
  }
}

/* Media query para dispositivos con un ancho máximo de 540px y alto máximo de 720px */
@media (max-width: 540px) and (max-height: 720px) {
  .landing-page {
    font-size: 16px; /* Ajusta el tamaño de fuente para dispositivos más pequeños */
  }

  .background-gradient {
    background-size: cover; /* Asegura que el fondo cubra completamente el área */
  }
}
