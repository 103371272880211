.container {
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.heading {
  font-family: "Nubifont";
  font-size: 3rem;
  margin-top: 1rem;
  text-align: center;
}

.credits-container {
  height: 100%;
  width: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: #f4f4f4;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  padding: 15px;
  max-height: 70vh; /* Establece una altura máxima */

  /* Media query para dispositivos con un ancho máximo de 768px */
  @media (max-width: 768px) {
    padding: 10px;
    font-size: 16px;
    width: 90vw; /* Reduzco el ancho del contenedor en dispositivos móviles */
  }

  /* Media query para dispositivos con un ancho máximo de 375px */
  @media (max-width: 375px) {
    padding: 5px;
    font-size: 14px;
  }

  /* Media query para resoluciones específicas donde el contenido se desborda */
  @media (max-width: 820px) {
    /* Ajusta los estilos según tus necesidades */
    font-size: 14px;
    padding: 10px;
  }

  @media (max-width: 912px) {
    /* Ajusta los estilos según tus necesidades */
    font-size: 14px;
    padding: 10px;
  }

  @media (max-width: 1024px) {
    /* Ajusta los estilos según tus necesidades */
    font-size: 14px;
    padding: 10px;
  }

  @media (max-width: 1280px) {
    /* Ajusta los estilos según tus necesidades */
    font-size: 14px;
    padding: 10px;
  }
}

.credit {
  font-size: 22px;
  margin: 5px 0;

  @media (max-width: 768px) {
    font-size: 18px;
  }
}

.tc-credit-container {
  display: flex;
  flex-direction: row; /* Cambio el diseño a columna en dispositivos móviles */
}

@media (max-width: 768px) {
  .tc-credit-container {
    flex-direction: column; /* En la versión móvil, cambiar a una columna */
  }
}

.tc-credit {
  display: flex;
  flex-direction: column;
  padding: 20px;
  margin: 20px;
  font-size: 22px;

  @media (max-width: 768px) {
    font-size: 18px;
    padding: 0px;
    margin: 0px;
  }
}

.logo {
  height: 200px;
  width: 200px;
}

@media (width: 280px) and (height: 653px) {
  .heading {
    font-size: 2.5rem; /* Ajusta el tamaño de la fuente para la pantalla específica */
  }
}